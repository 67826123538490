'use client';

import { Button } from '@repo/ui/components/ui/button';

export default function ReturnHomeButton() {
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  };

  return (
    <Button variant="outline" onClick={logout}>
      Try again
    </Button>
  );
}
